import React, { ComponentProps, useContext } from "react";
import { Button, Typography, styled } from "@mui/material";
import { useTranslations } from "next-intl";
import SelectMenuContext from "./SelectMenuContext";

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.button.activeText};
  cursor: pointer;
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
`,
);

type SelectMenuSelectAllProps = { allSelected: boolean } & ComponentProps<typeof Button>;

export default function SelectMenuSelectAll(props: SelectMenuSelectAllProps) {
  const { allSelected, ...restProps } = props;
  const { disabled } = useContext(SelectMenuContext);
  const t = useTranslations("filters");

  return (
    <Button
      disabled={disabled}
      variant="text"
      sx={{ display: "flex", marginLeft: "auto", p: 0, textTransform: "none" }}
      {...restProps}
    >
      <StyledTypography variant="label" fontWeight="bold">
        {allSelected ? t("deselect_all") : t("select_all")}
      </StyledTypography>
    </Button>
  );
}
