import React, { useState, useEffect } from 'react';
import { Box, TextField, InputAdornment, Typography, Stack, IconButton } from '@mui/material';
import { useTheme } from '@/theme/ThemeContext';
import { useQuery } from '@apollo/client';
import { useAppSelector } from '@/store/store';
import { convertUTCToLocalTime } from '@/utis/ApiDateFormats';
import { formatDateTimeYear } from '@/utis/formatDateTime';
import { ClearIcon } from '@mui/x-date-pickers';
import SensorsIcon from "@mui/icons-material/Sensors";
import formatLeagueName from '@/utis/formatLeagueName';
import { motion } from 'framer-motion';
import { getLeagueFromTypename } from '@/utis/formatTypeNameLeague';
import { toTitleCase } from '@/utis/formatNameToTitleCase';
import { useRouter } from 'next/navigation';
import SearchIcon from '../Icons/SearchIcon';
import { getTeamName } from '../SportsBook/helper';
import { LiveDataDesign } from '../LiveData/DesignEnum';
import { League } from '../Common/Enums/LeagueEnum';
import LiveData from '../LiveData/LiveData';
import { IGameType } from '../Odds/common';
import PageSpinner from '../PageSpinner';
import StyledTypography from "../StyledComponents/EllipseTypography";
import { SEARCH_LIVE_QUERY } from './common';

interface SearchLiveMobileProps {
    hideSearchBar: () => void;
}

function SearchLiveMobile({ hideSearchBar }: SearchLiveMobileProps) {
    const theme = useTheme();
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    const gameLiveData = useAppSelector((state) => state.gameLiveDataReducer);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (query.length > 2) {
                setDebouncedQuery(query);
            } else {
                setDebouncedQuery('');
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    const { loading, error, data } = useQuery(SEARCH_LIVE_QUERY, {
        variables: { query: debouncedQuery },
        skip: !debouncedQuery,
    });

    const router = useRouter();

    const handleGameClick = (league: League, gameId: string) => {
        router.push(`/game/${league}/${gameId}`);
        hideSearchBar();
    };

    const handlePlayerClick = (league: League, gameId: string, playerId: string) => {
        router.push(`/game/${league}/${gameId}?panelValue=3&selectedPlayerId=${playerId}`);
        hideSearchBar();
    };

    const handleCloseClick = () => {
        setQuery('');
    };

    return (
        <Box
            sx={{
                width: '94%',
                height: '100%',
                margin: "0 auto",
                backgroundColor: theme.palette.background.default,
            }}
        >
            <Stack direction="row" justifyContent="center" alignItems="center" px={0.2}>
                <TextField
                    size="small"
                    variant="standard"
                    placeholder="Find games and players"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ color: "text.secondary", pl: 1 }}>
                                <SearchIcon sx={{ width: "18px" }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleCloseClick} sx={{
                                    width: 14,
                                    borderRadius: "8px",
                                    py: 1.2,
                                    px: 1.8,
                                }}>
                                    <ClearIcon sx={{
                                        fontSize: "14px",
                                        display: query ? "block" : "none",
                                    }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                    sx={{
                        flexGrow: 1,
                        transition: "width 0.3s ease-in-out",
                        padding: 0.5,
                        mt: 1,
                        mb: 0.5,
                        backgroundColor: theme.palette.customBackground.cardHeader,
                        borderRadius: theme.borderRadius(2),
                        width: "100%",
                        alignSelf: "center",

                        "& .MuiInputBase-input": { fontSize: 14, height: 14, py: 1 },
                    }}
                />
            </Stack>
            <Box sx={{
                border: data && `1px solid ${theme.palette.grayScale.eightpercent}`, overflow: "auto",
                borderRadius: "16px",
                backgroundColor: theme.palette.customBackground.cards,
            }} >
                <motion.div
                    initial={{ opacity: 0, y: -100, scale: 1 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, y: -100, scale: 1 }}
                    transition={{ type: "spring", stiffness: 200, damping: 20, duration: 0.5 }}
                >
                    {!data && query.length > 0 && <PageSpinner />}
                    {error && <Typography variant="caption" color="error">Error: {error.message}</Typography>}
                    {!loading && data && data.search.live.games.length === 0 && data.search.live.players.length === 0 && (

                        <Typography variant="label" fontWeight="bold" sx={{
                            p: 2,
                            backgroundColor: theme.palette.customBackground.cardHeader,
                            display: "block"
                        }}>
                            No results found
                        </Typography>
                    )}

                    {data && data.search.live.games && data.search.live.games.length > 0 && (
                        <Box sx={{
                            backgroundColor: theme.palette.customBackground.cardHeader,
                        }}>
                            <Box sx={{
                                backgroundColor: theme.palette.customBackground.cards,
                            }}>
                                <Box height={45} sx={{
                                    backgroundColor: theme.palette.customBackground.cardHeader,
                                    borderRadius: "16px 16px 0px 0px",
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <Typography variant="karantinaHeader" fontSize={18} sx={{ px: 2.5, }}>
                                        Games
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                backgroundColor: theme.palette.customBackground.cardHeader,
                                borderRadius: "16px 16px 0px 0px",
                                border: `1px solid ${theme.palette.grayScale.sixpercent}`,
                                borderTop: "none",
                                borderBottom: "none"
                            }}>
                                {data.search.live.games.map((game: IGameType, index: number) => {
                                    const { awayTeam, homeTeam, startDateTime, __typename, id } = game;
                                    const league = getLeagueFromTypename(__typename);
                                    const backgroundColor = index % 2 !== 0 ? theme.palette.customBackground.cards : theme.palette.primary.main;

                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                backgroundColor, borderRadius: index === 0 ? "16px 16px 0px 0px" : "0px", px: 2.5, cursor: 'pointer',
                                                borderTop: index === 0 ? `1px solid ${theme.palette.grayScale.sixpercent}` : "none",
                                            }}
                                            onClick={() => handleGameClick(league, id)}
                                        >
                                            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" height={65}>
                                                <Stack flexDirection="column" flex={1} maxWidth={130}>
                                                    <StyledTypography variant="body2">
                                                        {getTeamName(awayTeam, league, false)}
                                                    </StyledTypography>
                                                    <StyledTypography variant="body2">
                                                        {getTeamName(homeTeam, league, false)}
                                                    </StyledTypography>
                                                    <StyledTypography variant="smallText" color="text.secondary" fontWeight="bold">
                                                        {formatLeagueName(league)}
                                                    </StyledTypography>
                                                </Stack>
                                                <Stack flexDirection="column" px={1} flex={1}>
                                                    {gameLiveData[id] ? (
                                                        <Box position="relative" width="100px" p={1}>
                                                            <Box sx={{ transform: 'scale(1)', transformOrigin: 'top left' }}>
                                                                <LiveData gameId={id} leagueId={league} design={LiveDataDesign.DEFAULT} startTime={startDateTime} showAdditionalData={false} />
                                                            </Box>
                                                            <Box position="absolute" top={6} right={-35}
                                                                sx={{
                                                                    backgroundColor: theme.palette.grayScale.sixpercent,
                                                                    borderRadius: "8px",
                                                                    px: 0.3,
                                                                    m: 0,
                                                                    height: "22px"
                                                                }}>
                                                                <SensorsIcon color="error" sx={{
                                                                    fontSize: 16,
                                                                }} />
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <StyledTypography color="text.secondary" variant="label" textAlign="center">
                                                            {startDateTime ? (
                                                                <>
                                                                    {formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[0]}
                                                                    <br />
                                                                    <b>{formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[1]}</b>
                                                                </>
                                                            ) : null}
                                                        </StyledTypography>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                    {data && data.search.live.players && data.search.live.players.length > 0 && (
                        <Box sx={{
                            backgroundColor: theme.palette.customBackground.cardHeader,
                        }}>
                            <Box sx={{
                                backgroundColor: data && data.search.live.games.length % 2 === 0 ? theme.palette.customBackground.cards : theme.palette.primary.main,
                            }}>
                                <Box height={45} sx={{
                                    backgroundColor: theme.palette.customBackground.cardHeader,
                                    borderRadius: "16px 16px 0px 0px",
                                    borderTop: `1px solid ${theme.palette.grayScale.sixpercent}`,
                                    display: "flex",
                                    alignItems: "center",

                                }}>
                                    <Typography variant="karantinaHeader" fontSize={18} sx={{ px: 2.5, display: "block" }}>
                                        Players
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                backgroundColor: theme.palette.customBackground.cardHeader,
                                borderRadius: "16px 16px 0px 0px",
                                border: `1px solid ${theme.palette.grayScale.sixpercent}`,
                                borderBottom: "none",
                                borderTop: "none",
                            }}>
                                {data.search.live.players.map((player: { player: { name: string, id: number }, game: IGameType }, index: number) => {
                                    const { name, id: playerId } = player.player;
                                    const { __typename, awayTeam, homeTeam, startDateTime, id: gameId } = player.game;
                                    const league = getLeagueFromTypename(__typename);
                                    const backgroundColor = index % 2 === 0 ? theme.palette.primary.main : theme.palette.customBackground.cards;

                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                backgroundColor, borderRadius: index === 0 ? "16px 16px 0px 0px" : "0px", px: 2.5, cursor: 'pointer',
                                                borderTop: index === 0 ? `1px solid ${theme.palette.grayScale.sixpercent}` : "none",
                                            }}
                                            onClick={() => handlePlayerClick(league, gameId, playerId.toString())}
                                        >
                                            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" height={45}>
                                                <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ flex: 1.5, pr: 1 }}>
                                                    <StyledTypography variant="body2" maxWidth={130}>
                                                        {toTitleCase(name)}
                                                    </StyledTypography>
                                                    <StyledTypography variant="smallText" color="text.secondary" fontWeight="bold">
                                                        {formatLeagueName(league)}
                                                    </StyledTypography>
                                                </Stack>
                                                <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ flex: 1, textAlign: "center" }}>
                                                    <StyledTypography variant="label" color="text.secondary" lineHeight="12px">
                                                        {getTeamName(awayTeam, league, true)}
                                                    </StyledTypography>
                                                    <StyledTypography variant="label" color="text.secondary" lineHeight="12px">
                                                        {getTeamName(homeTeam, league, true)}
                                                    </StyledTypography>
                                                </Stack>
                                                <Stack flexDirection="column" alignItems="flex-end" px={1} sx={{ flex: 0.5, textAlign: 'center' }}>
                                                    {gameLiveData[gameId] ? (
                                                        <Stack direction="row" alignItems="center" gap={0.5}>
                                                            <StyledTypography color="text.secondary" variant="label" lineHeight="12px" textAlign="left" pr={2}>
                                                                {startDateTime ? (
                                                                    <>
                                                                        Live
                                                                        <br />
                                                                        <b>{formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[0]}</b>
                                                                    </>
                                                                ) : null}
                                                            </StyledTypography><Box display="flex" position="absolute" right={10}>
                                                                <SensorsIcon color="error" sx={{
                                                                    backgroundColor: theme.palette.grayScale.sixpercent,
                                                                    borderRadius: "6px",
                                                                    p: 0.3,
                                                                    fontSize: 18,
                                                                }} />
                                                            </Box></Stack>
                                                    ) : (
                                                        <StyledTypography color="text.secondary" variant="label" lineHeight="12px">
                                                            {startDateTime ? (
                                                                <>
                                                                    {formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[0]}
                                                                    <br />
                                                                    <b>{formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[1]}</b>
                                                                </>
                                                            ) : null}
                                                        </StyledTypography>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </motion.div>
            </Box>
        </Box>
    );
}

export default SearchLiveMobile;