export const FOOTBALL_LIVE_DATA_TYPE_FRAGMENT = `
  fragment FootballLiveDataTypeFragment on FootballLiveDataType {
  gameId
  additionalData
  period
  gameTimeSeconds
  homeTeamHasPossession
  activeDown
  yardsToNextDown
  yardsDistance
  awayTeamDrives
  homeTeamDrives
  awayTeamPasses
  homeTeamPasses
  awayTeamTurnovers
  homeTeamTurnovers
  awayTeamPenalties
  homeTeamPenalties
  awayTeamOnePointConversions
  homeTeamOnePointConversions
  awayTeamTwoPointConversions
  homeTeamTwoPointConversions
  awayTeamSafeties
  homeTeamSafeties
  awayTeamFieldGoals
  homeTeamFieldGoals
  awayTeamCoachChallenges
  homeTeamCoachChallenges
  awayTeamTotalPoints
  homeTeamTotalPoints
  awayTeamTouchdowns
  homeTeamTouchdowns
  awayTeamTimeouts
  homeTeamTimeouts
  
  players {
  playerId
  passing {
    attempts
    completions
    yards
    longestYards
    touchdowns
    interceptions
    sacked
  }
  rushing {
    attempts
    yards
    touchdowns
    longestYards
  }
  receiving {
    receptions
    targets
    yards
    touchdowns
    longestYards
  }
  kicking {
    fieldGoalAttempts
    fieldGoalMade
    longestYards
    extraPointAttempts
    extraPointMade
  }
  defense {
    tackles
  sacks
  interceptions
  forcedFumbles
  touchdowns
  }
  }
  }
`;

type FootballGameLivePlayerPassingDataType = {
  attempts: number;
  completions: number;
  yards: number;
  longestYards: number;
  touchdowns: number;
  interceptions: number;
  sacked: number;
}
type FootballGameLivePlayerRushingDataType = {
  attempts: number;
  yards: number;
  touchdowns: number;
  longestYards: number;
}
type FootballGameLivePlayerReceivingDataType = {
  receptions: number;
  targets: number;
  yards: number;
  touchdowns: number;
  longestYards: number;
}
type FootballGameLivePlayerKickingDataType = {
  fieldGoalAttempts: number;
  fieldGoalMade: number;
  longestYards: number;
  extraPointAttempts: number;
  extraPointMade: number;
}
type FootballGameLivePlayerDefenseDataType = {
  tackles: number;
  sacks: number;
  interceptions: number;
  forcedFumbles: number;
  touchdowns: number;
}


export type FootballLivePlayerDataType = {
  playerId: number;
  passing: FootballGameLivePlayerPassingDataType | null;
  rushing: FootballGameLivePlayerRushingDataType | null;
  receiving: FootballGameLivePlayerReceivingDataType | null;
  kicking: FootballGameLivePlayerKickingDataType | null;
  defense: FootballGameLivePlayerDefenseDataType | null;
}

export type FootballLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  activeDown: string | null;
  yardsToNextDown: number[] | null;
  yardsDistance: number[] | null;
  awayTeamDrives: number[] | null;
  homeTeamDrives: number[] | null;
  awayTeamPasses: number[] | null;
  homeTeamPasses: number[] | null;
  awayTeamTurnovers: number[] | null;
  homeTeamTurnovers: number[] | null;
  awayTeamPenalties: number[] | null;
  homeTeamPenalties: number[] | null;
  awayTeamOnePointConversions: number[] | null;
  homeTeamOnePointConversions: number[] | null;
  awayTeamTwoPointConversions: number[] | null;
  homeTeamTwoPointConversions: number[] | null;
  awayTeamSafeties: number[] | null;
  homeTeamSafeties: number[] | null;
  awayTeamFieldGoals: number[] | null;
  homeTeamFieldGoals: number[] | null;
  awayTeamCoachChallenges: number[] | null;
  homeTeamCoachChallenges: number[] | null;
  awayTeamTotalPoints: number[] | null;
  homeTeamTotalPoints: number[] | null;
  awayTeamTouchdowns: number[] | null;
  homeTeamTouchdowns: number[] | null;
  awayTeamTimeouts: number[] | null;
  homeTeamTimeouts: number[] | null;
  players: FootballLivePlayerDataType[] | null;
};
