export enum IGameType {
  NBAGameType = "NBAGameType",
  NFLGameType = "NFLGameType",
  NCAAFGameType = "NCAAFGameType",
  NHLGameType = "NHLGameType",
  NCAABGameType = "NCAABGameType",
  EuroLeagueBBGameType = "EuroLeagueBBGameType",
  EUFAChampionsGameType = "EUFAChampionsGameType",
  LaLlgaGameType = "LaLlgaGameType",
  EnglishPremierGameType = "EnglishPremierGameType",
  FrenchLigue1GameType = "FrenchLigue1GameType",
  GermanBundesligaGameType = "GermanBundesligaGameType",
  ItalianSeriaAGameType = "ItalianSeriaAGameType",
  MLBGameType = "MLBGameType",
  TennisGameType = "TennisGameType",
  WNBAGameType = "WNBAGameType",
  UEFAEuroGameType = "UEFAEuroGameType",
  CopaAmericaGameType = "CopaAmericaGameType",
  CFLGameType = "CFLGameType",
  MLSGameType = "MLSGameType",
  BrazilSerieAGameType = "BrazilSerieAGameType",
  NBASummerGameType = "NBASummerGameType",
  OlympicsBasketballGameType = "OlympicsBasketballGameType",
  OlympicsBasketballWomenGameType = "OlympicsBasketballWomenGameType",
  OlympicsSoccerGameType = "OlympicsSoccerGameType",
  OlympicsSoccerWomenGameType = "OlympicsSoccerWomenGameType",
  UEFAEuropaGameType = "UEFAEuropaGameType",
}

export const generateIGameTypeFragment = (gameType: IGameType) => `
  fragment IGameType${gameType}Fragment on ${gameType} {
    startDateTime
    id
  }
`;

export const IGameTypeWeatherFragment = `
  fragment IGameTypeWeatherFragment on GameWeatherType {
    time
    tempF
    condition
    windMPH
    windDirection
    feelsLikeTempF
    chanceOfRain
    precipInches
  }
`;

export type GameWeatherType = {
  time: number | null;
  tempF: number;
  condition: string;
  windMPH: number;
  windDirection: string;
  feelsLikeTempF: number;
  chanceOfRain: number;
  precipInches: number;
};

export type IGameTypeFragment = {
  id: string;
  startDateTime: number;
};
