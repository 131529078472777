import { useAppSelector } from "@/store/store";
import { ServerStatusEnum } from "@/store/toolsSportCounts";
import { Alert, Box, Link, useTheme } from "@mui/material";
import React from "react";

function ServerStatusHeaderMessage() {
  const theme = useTheme();
  const serverStatus = useAppSelector((state) => state.toolsSportCountsReducer.serverStatusId);

  if (serverStatus !== ServerStatusEnum.MAINTENANCE) {
    return null;
  }

  return (
    <>
      <Alert icon={false} variant="outlined" severity="warning" sx={{
        borderColor: theme.palette.error.main,
        color: theme.palette.text.primary,
        borderRadius: "8px",
      }}>
        🛠️ Website Upgrade in Progress: For live updates, join our
        <Link
          href="https://discord.gg/9dsyWsbnRx"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ ml: 0.75, color: "inherit", fontWeight: "bold" }}
        >
          Discord here
        </Link>
        .
      </Alert>
      <Box sx={{ flexGrow: 1 }} />
    </>
  );
}

export default ServerStatusHeaderMessage;
