import React, { ComponentProps, useContext } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { ListItemIcon, Typography, styled, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTheme } from "@/theme/ThemeContext";
import SelectMenuContext from "./SelectMenuContext";

export const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "isWarning",
})<{ isWarning?: boolean }>(
  ({ isWarning, theme }) => `
  padding: 0;
  height: 32px;
  color: ${theme.palette.text.primary};
  border: none;
  background-color: ${theme.palette.grayScale.fourpercent};
  transition: color 0.3s ease-in-out;
  &:hover {
    border: none;
  background-color: ${theme.palette.grayScale.fourpercent};
   color: ${theme.palette.text.secondary};
  }

  &.MuiButton-outlined {
    border-color: ${isWarning ? theme.palette.warningLight : "none"};
  }
  `,
);

type SelectMenuDisclosureProps = {
  children?: React.ReactNode;
  dropDownIcon?: boolean;
  image?: React.ReactNode;
  isWarning?: boolean;
} & Omit<ComponentProps<typeof Button>, "variant"> &
  Partial<Pick<ButtonProps, "variant">>;

export default function SelectMenuDisclosure(props: SelectMenuDisclosureProps) {
  const { children, dropDownIcon, image, variant = "outlined", isWarning, ...restProps } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { menuState, titleInfo } = useContext(SelectMenuContext);

  const buttonClicked = (ev: React.MouseEvent<Element, MouseEvent>) => {
    if (menuState.anchorEl) {
      menuState.onClose();
    } else {
      menuState.onOpen(ev);
    }
  };

  // Fix this so not include title button clickkk in buttOn
  return (
    <StyledButton
      variant={isWarning ? "outlined" : variant}
      onClick={buttonClicked}
      isWarning={isWarning}
      fullWidth
      {...restProps}
    >
      {image && <ListItemIcon sx={{ pl: 1, minWidth: "fit-content" }}>{image}</ListItemIcon>}
      <Typography variant="body2" display="flex" py={1} px={1.5} pr={dropDownIcon ? 0 : 1} color="inherit" mr="auto">
        {children}
      </Typography>
      {isMobile && titleInfo}
      {dropDownIcon &&
        (menuState.anchorEl ? (
          <ArrowDropUpIcon sx={{ fill: theme.palette.text.primary }} />
        ) : (
          <ArrowDropDownIcon sx={{ fill: theme.palette.text.primary }} />
        ))}
    </StyledButton>
  );
}
