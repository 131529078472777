import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import SearchIcon from "../Icons/SearchIcon";
import SelectMenuContext from './SelectMenuContext';

type SelectMenuTextFieldProps = TextFieldProps & {
    onSearchChange: (value: string) => void;
    onClose: () => void;
};

export default function SelectMenuTextField(props: SelectMenuTextFieldProps) {
    const { onSearchChange, onClose, ...restProps } = props;
    const theme = useTheme();
    const { menuState } = React.useContext(SelectMenuContext);
    const [query, setQuery] = useState('');
    const [previousQuery, setPreviousQuery] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            if (query.length > 0) {
                onSearchChange(query);
                menuState.onOpen({ currentTarget: document.getElementById('search-input')! } as React.ChangeEvent<HTMLInputElement>);
            } else {
                onSearchChange('');
                menuState.onClose();
                onClose();
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [query, onSearchChange, menuState, onClose]);

    const handleCloseClick = () => {
        setPreviousQuery(query);
        setQuery('');
        onClose();
    };

    useEffect(() => {
        if (!menuState.isOpen) {
            setPreviousQuery(query);
            setQuery('');
        }
    }, [menuState.isOpen]);

    useEffect(() => {
        if (query) {
            setPreviousQuery(query);
        }
    }, [query]);

    return (
        <Box id="search-input">
            <TextField
                size="small"
                variant="standard"
                placeholder={previousQuery || "Find games and players"}
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                }}
                onFocus={() => setQuery(previousQuery)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ color: "text.secondary", pl: 1 }}>
                            <SearchIcon sx={{ width: "18px" }} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Box sx={{
                                width: 20
                            }}>
                                {query.length > 0 && (
                                    <IconButton onClick={handleCloseClick} sx={{
                                        width: 14,
                                        borderRadius: "8px",
                                        py: 1,
                                        px: 1.4,
                                        mr: 0.5,
                                    }}>
                                        <ClearIcon sx={{
                                            fontSize: "14px",
                                            display: query ? "block" : "none",
                                        }} />
                                    </IconButton>
                                )}
                            </Box>
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
                sx={{
                    flexGrow: 1,
                    transition: "width 0.3s ease-in-out",
                    padding: 0.5,
                    backgroundColor: theme.palette.customBackground.cardHeader,
                    borderRadius: theme.borderRadius(2),

                    "& .MuiInputBase-input": { fontSize: 14, width: 242, height: 8, py: 1 },
                }}
                {...restProps}
            />
        </Box>
    );
}