import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.499 2a8.501 8.501 0 1 0 5.263 15.176l3.651 3.651a1 1 0 0 0 1.414 -1.414l-3.651 -3.651A8.501 8.501 0 0 0 10.499 2M4 10.501a6.499 6.499 0 1 1 13 0 6.499 6.499 0 0 1 -13 0"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
