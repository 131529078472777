import React, { useState } from "react";
import { Modal, Box, Divider, IconButton, styled } from "@mui/material";
import { useTheme } from "@/theme/ThemeContext";
import CloseRoundedIcon from "../Icons/CloseRoundedIcon";
import SearchIcon from "../Icons/SearchIcon";
import Header from "./Header";
import SearchLiveMobile from "../SearchLive/SearchLiveMobile";

const StyledBox = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: ${theme.palette.primary.main};
    height: 100%;
  `,
);

function SearchModal() {
    const theme = useTheme();
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const toggleSearchVisibility = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const hideSearchBar = () => {
        setIsSearchVisible(false);
    };

    return (
        <>
            <IconButton onClick={toggleSearchVisibility}>
                <SearchIcon sx={{ color: 'text.primary', fill: 'text.secondary', fontSize: 20 }} />
            </IconButton>
            <Modal open={isSearchVisible} onClose={hideSearchBar}>
                <StyledBox>
                    <Header
                        endComponent={
                            <IconButton onClick={hideSearchBar} size="small" sx={{ borderRadius: "8px", width: 32, height: 32, backgroundColor: theme.palette.grayScale.sixpercent, }}>
                                <CloseRoundedIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                        }
                        searchComponent={
                            <IconButton onClick={toggleSearchVisibility}>
                                <SearchIcon sx={{ color: 'text.secondary', fill: 'text.secondary', fontSize: 20 }} />
                            </IconButton>
                        }
                    />
                    <Divider />
                    <Box sx={{
                        width: '100%',
                        borderTop: `1px solid ${theme.palette.divider}`,
                        overflowY: 'auto',
                    }}>
                        <SearchLiveMobile hideSearchBar={hideSearchBar} />
                    </Box>
                </StyledBox>
            </Modal>
        </>
    );
}

export default SearchModal;