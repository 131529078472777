import React, { ComponentProps, useContext } from "react";
import { MenuItem, styled } from "@mui/material";
import SelectMenuContext from "./SelectMenuContext";

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (propName) => propName !== "inActive",
})<{ inActive?: boolean }>(
  ({ inActive }) => `
  opacity: ${inActive ? 0.38 : 1};
  height: 30;
  padding-left: 12px;
  padding-right: 0px;
  
`,
);

type SelectMenuItemProps = { inActive?: boolean } & ComponentProps<typeof MenuItem>;

export default function SelectMenuItem(props: SelectMenuItemProps) {
  const { onClick: onClickProp, inActive, children, ...restProps } = props;

  const { menuState, disabled } = useContext(SelectMenuContext);

  const handleClick = (ev: React.MouseEvent<HTMLLIElement>) => {
    if (onClickProp) onClickProp(ev);

    // Check if the click is within a nested SelectMenu
    const targetElement = ev.target as Element;
    if (!ev.defaultPrevented && !targetElement.closest(".nested-select-menu")) {
      menuState.onClose();
    }
  };

  return (
    <StyledMenuItem onClick={handleClick} disabled={disabled} inActive={inActive} {...restProps}>
      {children}
    </StyledMenuItem>
  );
}
