import React, { useState, useEffect } from "react";
import { List, useEventCallback } from "@mui/material";
import Scrollable from "../Scrollable";

type SelectMenuListProps = {
  children: React.ReactNode;
  getHeight?: () => number;
  listRef?: React.RefObject<HTMLUListElement>;
  scrollBarMargin?: number;
};

export default function SelectMenuList(props: SelectMenuListProps) {
  const { children, getHeight: getHeightProp = () => Math.min(240, 0.6 * window.innerHeight), listRef, scrollBarMargin } = props;
  const getHeight = useEventCallback(getHeightProp);
  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => setMaxHeight(getHeight);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getHeight]);

  return (
    <Scrollable maxHeight={maxHeight} sx={{ borderRadius: "16px" }} scrollBarMargin={scrollBarMargin}>
      <List role="menu" ref={listRef} sx={{ pt: 0, pb: 0 }}>
        {children}
      </List>
    </Scrollable>
  );
}