import React, { useEffect, useRef } from "react";
import { Box, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import DiscordBrandIcon from "@/assests/icons/DiscordBrandIcon";
import Link from "next/link";
import { karantina } from "@/utis/fonts";
import XIcon from "@mui/icons-material/X";
import gsap from "gsap";
import { useTranslations } from "next-intl";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.customBackground.footer,
}));

const StyledInnerWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(7)}`,
  maxWidth: "1312px",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    padding: `0 ${theme.spacing(2.5)}`,
  },
}));

const StyledTop = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(12)} 0`,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} 0`,
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const StyledLogos = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(6),
  },
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "50px",
  lineHeight: 1,
  textTransform: "uppercase",
  fontWeight: "bold",
  maxWidth: `${theme.spacing(80)}`,
  color: `${theme.palette.customBackground.custom}`,
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    fontSize: "38px",
  },
}));

const StyledBottom = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} 0`,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  "& p": {
    width: "50%",
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.customBackground.custom,
    margin: 0,
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    flexDirection: "column",
    alignItems: "stretch",
    rowGap: theme.spacing(3),
    "& p": {
      width: "100%",
    },
  },
}));

const LogogWrapper = styled("a")(({ theme }) => ({
  background: theme.palette.text.primary,
  borderRadius: "8px",
  padding: theme.spacing(1),
  display: "flex",
  color: theme.palette.customBackground.cards,
}));

const GradientBox = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  background: `linear-gradient(to right, transparent, ${theme.palette.customBackground.custom}, transparent)`,
}));

const LinkWrapper = styled(Link)(({ theme }) => ({
  color: theme.palette.customBackground.custom,
  fontWeight: "bold",
}));

const QuestionMarkSpan = styled("span")(({ theme }) => ({
  fontSize: "60px",
  color: theme.palette.customBackground.custom,
  [theme.breakpoints.down("md")]: {
    fontSize: "44px"
  },
}));

function LineBreak() {
  return <br />;
}

function WordSpan({ chunks, href }: { chunks: React.ReactNode; href: string }) {
  return (
    <LinkWrapper href={href} sx={{ textDecoration: "none" }}>
      {Array.isArray(chunks)
        ? chunks.map((chunk, index) =>
          chunk === "?" ? (
            <QuestionMarkSpan key={index}>{chunk}</QuestionMarkSpan>
          ) : (
            <span key={index} className="footer-word">
              {chunk}
            </span>
          )
        )
        : chunks}
    </LinkWrapper>
  );
}

function WordSpan1(chunks: React.ReactNode) {
  if (Array.isArray(chunks) && (chunks[0] === "Privacy Policy" || chunks[0] === "Politique de confidentialité")) {
    return (
      <LinkWrapper className="footer-word" href="/privacy-policy">
        {chunks}
      </LinkWrapper>
    );
  }
  if (Array.isArray(chunks) && chunks[0] === "Terms of Service") {
    return (
      <LinkWrapper className="footer-word" href="/terms-of-service">
        {chunks}
      </LinkWrapper>
    );
  }
  return chunks;
}

const renderSpan = (chunks: React.ReactNode) => {
  const word = chunks?.toString();
  let href = "#";
  if (word === "DISCORD") {
    href = "https://discord.gg/9dsyWsbnRx";
  } else if (word === "X") {
    href = "https://x.com/PickTheOdd";
  }
  return <WordSpan chunks={chunks} href={href} />;
};
const renderSpanTwo = (chunks: React.ReactNode) => (
  <WordSpan chunks={chunks} href="https://www.bonfire.com/store/pick-the-odds/" />
);
function Footer() {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const t = useTranslations("Home");

  useEffect(() => {
    const triggerElem = triggerRef.current;

    const words = gsap.utils.toArray("span.footer-word");

    if (triggerElem) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: triggerElem,
            start: "top bottom",
            end: "+=10%",
            scrub: 0,
          },
        })
        .set(
          words,
          {
            color: theme.palette.text.primary,
            stagger: 1,
          },
          0.1
        );
    }
  }, [theme, triggerRef]);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledWrapper>
      <StyledInnerWrapper ref={triggerRef}>
        <StyledTop gap={1}>
          <Stack gap={2} direction="row" alignItems={isMobile ? "end" : "center"}>
            <StyledText>
              {t.rich("footer_title", {
                br: LineBreak,
                span: renderSpan,
              })}
            </StyledText>
            <StyledLogos display="flex" gap={2} ml="auto">
              <LogogWrapper href="https://discord.gg/9dsyWsbnRx" target="_blank" rel="noreferrer noopener">
                <DiscordBrandIcon sx={{ width: "18px", height: "18px" }} />
              </LogogWrapper>
              <LogogWrapper href="https://x.com/PickTheOdd" target="_blank" rel="noreferrer noopener">
                <XIcon sx={{ width: "18px", height: "18px" }} />
              </LogogWrapper>
            </StyledLogos>
          </Stack>
          <StyledText>
            {t.rich("footer_title_two", {
              br: LineBreak,
              span: renderSpanTwo,
            })}
          </StyledText>
        </StyledTop>
        <GradientBox />
        <StyledBottom>
          <Typography>{t.rich("tos", { br: LineBreak, span: WordSpan1 })}</Typography>
          <Typography>{t("footer_desc")}</Typography>
        </StyledBottom>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
}

export default Footer;