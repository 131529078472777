import React, { useCallback, useMemo, useState } from "react";
import SelectMenuContext, { SelectMenuContextValue } from "./SelectMenuContext";
import SelectMenuDisclosure from "./SelectMenuDisclosure";
import SelectMenuModal from "./SelectMenuModal";
import SelectMenuList from "./SelectMenuList";
import SelectMenuItem from "./SelectMenuItem";
import SelectMenuFilter from "./SelectMenuFilter";
import SelectMenuSelectAll from "./SelectMenuSelectAll";
import SelectMenuApply from "./SelectMenuApply";
import SelectMenuTitle from "./SelectMenuTitle";
import { SelectMenuVariant } from "./constant";
import SelectMenuWarning from "./SelectMenuWarning";
import SelectMenuCheckBox from "./SelectMenuCheckBox";
import SelectMenuText from "./SelectMenuText";
import SelectMenuTextField from "./SelectMenuTextField";

type SelectMenuProps = {
  children: React.ReactElement | React.ReactElement[];
  variant?: SelectMenuVariant;
  titleInfo?: React.ReactNode;
  disabled?: boolean;
};

function SelectMenu(props: SelectMenuProps) {
  const { children, variant, titleInfo, disabled } = props;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback((ev: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = ev;
    setAnchorEl((prev) => prev || currentTarget);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setIsOpen(false);
  }, []);

  const providerValue: SelectMenuContextValue = useMemo(
    () => ({
      menuState: { anchorEl, isOpen, onOpen: handleOpen, onClose: handleClose },
      variant: variant || SelectMenuVariant.POPPER,
      titleInfo,
      disabled,
    }),
    [anchorEl, isOpen, handleOpen, handleClose, variant, titleInfo, disabled],
  );

  return <SelectMenuContext.Provider value={providerValue}>{children}</SelectMenuContext.Provider>;
}

export default Object.assign(SelectMenu, {
  Context: SelectMenuContext,
  Modal: SelectMenuModal,
  Disclosure: SelectMenuDisclosure,
  List: SelectMenuList,
  Item: SelectMenuItem,
  Filter: SelectMenuFilter,
  SelectAll: SelectMenuSelectAll,
  Apply: SelectMenuApply,
  Title: SelectMenuTitle,
  Warning: SelectMenuWarning,
  CheckBox: SelectMenuCheckBox,
  Text: SelectMenuText,
  TextField: SelectMenuTextField,
});