import { Stack, Typography, styled } from "@mui/material";
import React, { ComponentProps } from "react";


const StyledStack = styled(Stack)<{ selected?: boolean }>(
  ({ theme, selected }) => `
  align-items: start;
  justify-content: center;
  width: 100%;
  background-color: ${selected ? theme.palette.grayScale.sixpercent : "transparent"};
  border-radius: ${theme.borderRadius(1.5)};
  padding: ${selected ? "4px" : "0"};
  padding-left: ${selected ? "6px" : "0px"};
  padding-right: ${selected ? "12px" : "0px"};
  margin-left: ${selected ? "-6px" : "0px"};
`,
);

type SelectMenuTextProp = {
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  primaryStyle?: ComponentProps<typeof Typography>;
  secondaryStyle?: ComponentProps<typeof Typography>;
  selected?: boolean;
};

function SelectMenuText(props: SelectMenuTextProp) {
  const { primary, secondary, primaryStyle, secondaryStyle, selected } = props;
  return (
    <StyledStack direction="column" selected={selected}>
      <Typography variant="body2" pr={2} color="inherit" {...primaryStyle}>
        {primary}
      </Typography>
      {secondary && (
        <Typography variant="body2" color="text.secondary" {...secondaryStyle}>
          {secondary}
        </Typography>
      )}
    </StyledStack>
  );
}

export default SelectMenuText;
