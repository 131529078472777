import React, { ComponentProps, useContext } from "react";
import { Checkbox, ListItemIcon, checkboxClasses } from "@mui/material";
import SelectMenuContext from "./SelectMenuContext";

type SelectMenuCheckBoxProps = ComponentProps<typeof Checkbox>;

export default function SelectMenuCheckBox(props: SelectMenuCheckBoxProps) {
  const { disabled } = useContext(SelectMenuContext);

  return (
    <ListItemIcon sx={{ height: 28 }}>
      <Checkbox
        disabled={disabled}
        edge="start"
        sx={{
          color: "text.primary",
          [`&.${checkboxClasses.checked}`]: {
            color: "text.primary",
          },
        }}
        tabIndex={-1}
        {...props}
      />
    </ListItemIcon>
  );
}
