import React, { useContext } from "react";
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectMenuContext from "./SelectMenuContext";
import { SelectMenuVariant } from "./constant";

type SelectMenuTitleProps = {
  children: React.ReactNode;
};

export default function SelectMenuTitle(props: SelectMenuTitleProps) {
  const { children } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { menuState, variant, titleInfo } = useContext(SelectMenuContext);

  if (variant === SelectMenuVariant.BOX) return null;

  const handleClickAway = () => {
    if (menuState.anchorEl) menuState.onClose();
  };

  return (
    <Box px={2} pb={2}>
      <Typography variant="h2" fontWeight="bold" mb={2} display="flex" alignItems="center">
        <Button variant="contained" size="small" sx={{ mr: 3, minWidth: "fit-content" }} onClick={handleClickAway}>
          <ArrowBackIcon />
        </Button>
        {children}
        {!isMobile && titleInfo}
      </Typography>
      <Divider />
    </Box>
  );
}
