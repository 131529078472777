import React from "react";
import { Alert, ButtonBase, Typography } from "@mui/material";
import { useUser } from "@/user/UserContext";
import { useTheme } from "@/theme/ThemeContext";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";

type SelectMenuWarningProps = {
  showWarning: boolean;
  children?: React.ReactNode;
};

export default function SelectMenuWarning(props: SelectMenuWarningProps) {
  const { children, showWarning } = props;
  const user = useUser();
  const theme = useTheme();
  const openLoginPopup = useUserSettingsContextSelector((ctx) => ctx.openLoginPopup);

  if (!showWarning) return null;
  return (
    <Alert icon={false} severity="warning" variant="outlined" sx={{ px: 1, py: 0, mb: 1, mx: 2 }}>
      <Typography color={theme.palette.warning.light} display="inline" variant="body2">
        {children || (
          <>
            <ButtonBase onClick={() => openLoginPopup()} sx={{ ":hover": { textDecoration: "underline" }, mx: "4px" }}>
              <Typography fontWeight="bold" color={theme.palette.warning.light} sx={{ textDecoration: "underline" }}>
                {user.isLoggedIn ? "Upgrade Now" : "Login Now"}
              </Typography>
            </ButtonBase>
            to remove the restrictions.
          </>
        )}
      </Typography>
    </Alert>
  );
}
