import { Box, styled, BoxProps } from "@mui/material";
import React, { useEffect, forwardRef, useRef, useState, useImperativeHandle } from "react";
import { debounce } from "@mui/material/utils";
import { detectSafari, updateScrollbarState, calculateScrollbarWidth } from "./ScrollBarUtil";

export const SCROLLBAR_SIZE = 6;
export const SCROLLBAR_MARGIN = 15;

interface ScrollableContainerProps extends BoxProps {
  hasScrollbar?: boolean;
  scrollbarWidth?: number;
  isSafari?: boolean;
  scrollBarMargin?: number;
}

const ScrollableContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "scrollBarMargin",
})<ScrollableContainerProps>(({ scrollBarMargin }) => ({
  overflowY: "auto",
  "&::-webkit-scrollbar-track": {
    marginTop: scrollBarMargin ? `${scrollBarMargin}px` : "2px",
    marginBottom: scrollBarMargin ? `${scrollBarMargin}px` : "2px",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    marginTop: scrollBarMargin ? `${scrollBarMargin}px` : "2px",
    marginBottom: scrollBarMargin ? `${scrollBarMargin}px` : "2px",
    borderRadius: "8px",
  },

}));

const Scrollable = forwardRef<HTMLDivElement, ScrollableContainerProps>(({ children, scrollBarMargin, ...rest }: ScrollableContainerProps, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const isSafari = detectSafari();

  useImperativeHandle(ref, () => containerRef.current as HTMLDivElement);

  useEffect(() => {
    updateScrollbarState(containerRef, setHasScrollbar);
    setScrollbarWidth(calculateScrollbarWidth());

    const debouncedUpdate = debounce(() => updateScrollbarState(containerRef, setHasScrollbar), 200);
    window.addEventListener("resize", debouncedUpdate); // Check on resize
    return () => {
      window.removeEventListener("resize", debouncedUpdate);
      debouncedUpdate.clear();
    };
  }, []);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!ref) return;
      const container = (ref as React.RefObject<HTMLDivElement>).current;
      if (!container) return;
      const distanceX = container.offsetLeft + container.offsetWidth - e.pageX;
      const distanceY = container.offsetTop + container.offsetHeight - e.pageY;
      if ((distanceX < 15 && distanceX > -15) || (distanceY < 15 && distanceY > -15)) {
        container.classList.add("expanded");
      } else {
        container.classList.remove("expanded");
      }
    };

    window.addEventListener("mousemove", listener);
    return () => {
      window.removeEventListener("mousemove", listener);
    };
  }, [ref]);

  useEffect(() => {
    updateScrollbarState(containerRef, setHasScrollbar);
  }, [children]);

  return (
    <ScrollableContainer
      ref={containerRef}
      hasScrollbar={hasScrollbar}
      scrollbarWidth={scrollbarWidth}
      scrollBarMargin={scrollBarMargin}
      isSafari={isSafari} // Pass down the Safari flag
      {...rest}
    >
      {children}
    </ScrollableContainer>
  );
});

Scrollable.displayName = "Scrollable";

export default Scrollable;