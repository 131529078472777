import { gql, useQuery, useSubscription } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { TENNIS_LIVE_DATA_TYPE_FRAGMENT } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import { BASEBALL_LIVE_DATA_TYPE_FRAGMENT } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import { BASKETBALL_LIVE_DATA_TYPE_FRAGMENT } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import { FOOTBALL_LIVE_DATA_TYPE_FRAGMENT } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import { ICE_HOCKEY_LIVE_DATA_TYPE_FRAGMENT } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import { SOCCER_LIVE_DATA_TYPE_FRAGMENT } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import { IGameLiveDataType } from "@/components/Common/GameLiveData/common";
import { gameLiveDataAction } from "@/store/gameLiveData";
import { League } from "@/components/Common/Enums/LeagueEnum";
import { Sports } from "@/components/Common/Enums/SportsEnum";

export const GET_GAME_LIVE_DATA = gql`
  subscription GetGameLiveData($league: LeagueEnum, $sport: SportEnum, $gameId: Guid) {
    gameLiveData(league: $league, sport: $sport, gameId: $gameId) {
      ... on TennisLiveDataType {
        ...TennisLiveDataTypeFragment
      }
      ... on SoccerLiveDataType {
        ...SoccerLiveDataTypeFragment
      }
      ... on IceHockeyLiveDataType {
        ...IceHockeyLiveDataTypeFragment
      }
      ... on FootballLiveDataType {
        ...FootballLiveDataTypeFragment
      }
      ... on BasketballLiveDataType {
        ...BasketballLiveDataTypeFragment
      }
      ... on BaseballLiveDataType {
        ...BaseballLiveDataTypeFragment
      }
    }
  }
  ${TENNIS_LIVE_DATA_TYPE_FRAGMENT}
  ${SOCCER_LIVE_DATA_TYPE_FRAGMENT}
  ${ICE_HOCKEY_LIVE_DATA_TYPE_FRAGMENT}
  ${FOOTBALL_LIVE_DATA_TYPE_FRAGMENT}
  ${BASKETBALL_LIVE_DATA_TYPE_FRAGMENT}
  ${BASEBALL_LIVE_DATA_TYPE_FRAGMENT}
`;

export type GetGameLiveData = {
  gameLiveData: IGameLiveDataType[];
};

export type GetGameLiveDataVariables = {
  league?: League;
  sport?: Sports;
  gameId?: string;
};

export default function useGetGameLiveData(variables: GetGameLiveDataVariables) {
  const dispatch = useAppDispatch();
  useSubscription<GetGameLiveData, GetGameLiveDataVariables>(GET_GAME_LIVE_DATA, {
    variables,
    onData: ({ data: subData }) => {
      const { data } = subData;
      if (!data) return;
      dispatch(gameLiveDataAction.setGameLiveDataList(data.gameLiveData));
    },
  });
  return null;
}

export const GET_GAME_LIVE_DATA_QUERY = gql`
  query GetGameLiveData($league: LeagueEnum, $sport: SportEnum, $gameId: Guid) {
    gameLiveData(league: $league, sport: $sport, gameId: $gameId) {
      ... on TennisLiveDataType {
        ...TennisLiveDataTypeFragment
      }
      ... on SoccerLiveDataType {
        ...SoccerLiveDataTypeFragment
      }
      ... on IceHockeyLiveDataType {
        ...IceHockeyLiveDataTypeFragment
      }
      ... on FootballLiveDataType {
        ...FootballLiveDataTypeFragment
      }
      ... on BasketballLiveDataType {
        ...BasketballLiveDataTypeFragment
      }
      ... on BaseballLiveDataType {
        ...BaseballLiveDataTypeFragment
      }
    }
  }
  ${TENNIS_LIVE_DATA_TYPE_FRAGMENT}
  ${SOCCER_LIVE_DATA_TYPE_FRAGMENT}
  ${ICE_HOCKEY_LIVE_DATA_TYPE_FRAGMENT}
  ${FOOTBALL_LIVE_DATA_TYPE_FRAGMENT}
  ${BASKETBALL_LIVE_DATA_TYPE_FRAGMENT}
  ${BASEBALL_LIVE_DATA_TYPE_FRAGMENT}
`;

export type GetGameLiveDataQuery = {
  gameLiveData: IGameLiveDataType[];
};

export function useGetGameLiveDataQuery(variables: GetGameLiveDataVariables) {
  const dispatch = useAppDispatch();
  const gameLiveDataList = useAppSelector((state) => state.gameLiveDataReducer);
  useQuery<GetGameLiveDataQuery, GetGameLiveDataVariables>(GET_GAME_LIVE_DATA_QUERY, {
    variables,
    onCompleted: ({ gameLiveData }) => {
      if (!gameLiveData?.length) return;
      dispatch(gameLiveDataAction.setGameLiveDataList(gameLiveData));
    },
    skip: Object.keys(gameLiveDataList).length > 0,
  });
  return null;
}
