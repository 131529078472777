import React, { useRef, useContext, useEffect, ComponentProps } from "react";
import { FormControl, TextField, styled } from "@mui/material";
import SelectMenuContext from "./SelectMenuContext";

export const StyledTextField = styled(TextField)(({ theme }) => `
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }
  background: ${theme.palette.grayScale.twelevepercent};
  border-radius: 8px;
  width: 100%;
  input {
    height: 26px;
    font-size: 14px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`);

const FilterWrapper = styled(FormControl)(
  ({ theme }) => `
  padding-left: ${theme.spacing(1)};
  padding-right: ${theme.spacing(1)};
  width: 100%;
  margin-top: ${theme.spacing(1)}; 

  &:last-child {
    margin-bottom: 0;
  }
`,
);

type SelectMenuFilterProps = ComponentProps<typeof TextField>;

export default function SelectMenuFilter(props: SelectMenuFilterProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const open = !!useContext(SelectMenuContext).menuState.anchorEl;
  useEffect(() => {
    const inputEl = inputRef.current;
    if (!inputEl) {
      return;
    }

    if (open) {
      inputEl.focus();
      return;
    }

    // reset search value on close
    /* We can't directly change the input value because because
      React Override Native Input Value Setter
      So we need to call the function directly on the input as context
      and then dispatch a change event
      For more info, refer -
      https://github.com/facebook/react/issues/10135
      https://github.com/facebook/react/issues/11488
    */
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
    if (nativeInputValueSetter) {
      nativeInputValueSetter.call(inputEl, "");
      inputEl.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }, [open]);

  return (
    <FilterWrapper>
      <StyledTextField
        size="small"
        // Autofocus on textfield
        autoFocus
        variant="outlined"
        fullWidth
        {...props}
      />
    </FilterWrapper>
  );
}
