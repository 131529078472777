import { ApolloLink, fromPromise, split } from "@apollo/client";
import { Client, ClientOptions, createClient, Message } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { RefreshAnonymousTokenParams } from "@/user/UserContext";
import * as Sentry from "@sentry/browser";
import { LocationType } from "@/user/user-settings/common";
import { gcGetApiTokenAsync } from "@/api/api";

declare module "@apollo/client" {
  export interface DefaultContext {
    token?: string | null;
  }
}

// cAwaitApiTokenLink should be used as the very first link in a chain
export const cAwaitApiTokenLink = new ApolloLink((operation, forward) => {
  if (typeof document === "undefined") {
    // NOTE: on server global api token will not be set. user's api token might
    // be provided during httpAuthLinkCreation, otherwise server-only API_TOKEN
    // env var will be used.
    return forward(operation);
  }
  return fromPromise(gcGetApiTokenAsync()).flatMap(() => forward(operation));
});

export function createHttpAuthLink({ getApiToken }: { getApiToken: () => string }) {
  return new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `bearer ${getApiToken()}`,
      },
    });
    return forward(operation);
  });
}

export function createSplitWsOrHttpLink({ wsLink, httpLink }: { wsLink: ApolloLink; httpLink: ApolloLink }) {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink,
  );
}

interface RestartableClient extends Client {
  restart(): void;
}

function createRestartableClient(options: ClientOptions): RestartableClient {
  let restartRequested = false;
  let restart = () => {
    restartRequested = true;
  };

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      opened: (socket: any) => {
        options.on?.opened?.(socket);

        restart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            socket.close(4205, "Client Restart");
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true;
          }
        };

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false;
          restart();
        }
      },
    },
  });

  return {
    ...client,
    restart: () => restart(),
  };
}

export const webSocket: {
  wsClient: RestartableClient | null;
  wsLink: GraphQLWsLink | null;
} = {
  wsClient: null,
  wsLink: null,
};

export const lastMessageTime = { time: Date.now() };

export function createWsLink(
  getApiToken: () => string,
  refreshUserAnonymousToken: (params?: RefreshAnonymousTokenParams) => Promise<string | null>,
  createNew?: boolean
) {
  if (!webSocket.wsClient || createNew) {
    let shouldRefreshToken = false;
    webSocket.wsClient = createRestartableClient({
      url: process.env.NEXT_PUBLIC_GRAPH_WS_ENDPOINT,
      lazy: true,
      shouldRetry: () => true,
      retryWait: async function waitForServerHealthyBeforeRetry() {

        // after the server becomes ready, wait for a second + random 1-4s timeout
        // (avoid DDoSing yourself) and try connecting again
        await new Promise((resolve) => {
          setTimeout(resolve, 1000 + Math.random() * 3000);
        });
      },
      connectionParams: async () => {
        if (shouldRefreshToken) {
          await refreshUserAnonymousToken();
          shouldRefreshToken = false;
        }
        const userToken = getApiToken();
        return {
          Authorization: userToken ? `Bearer ${userToken}` : "",
        };
      },
      on: {
        message: (message: Message) => {
          if (message?.type === "error" && message?.payload?.[0]?.message === "Authentication Failed") {
            shouldRefreshToken = true;
            Sentry.addBreadcrumb({
              category: "error",
              message: `WebSocket Authentication Failed`,
              data: message?.payload?.[0],
              level: "log",
            });
          }
          lastMessageTime.time = Date.now();
        },
        error: (_) => {
          // console.error('WebSocket error:', error);
        },
        closed: (_) => {
          // console.log('WebSocket closed:', event);
        },
      },

    });
  }
  webSocket.wsLink = new GraphQLWsLink(webSocket.wsClient);

  return webSocket.wsLink!;
}

export async function restartEntireWebSocket(
  getApiToken: () => string,
  refreshUserAnonymousToken: (params?: RefreshAnonymousTokenParams) => Promise<string | null>,
) {
  if (!webSocket.wsClient) {
    // eslint-disable-next-line no-console
    console.warn("WebSocket client is not initialized.");
    return;
  }
  webSocket.wsClient.dispose();
  createWsLink(getApiToken, refreshUserAnonymousToken, true);
  webSocket.wsClient.restart();
}

export function createLocationLink(location: LocationType) {
  return new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        region: location?.region,
      },
    });
    return forward(operation);
  });
}

let refreshAnonymousTokenPromise: Promise<string | null> | null = null;

export function createErrorLink(refreshUserAnonymousToken: (params?: RefreshAnonymousTokenParams) => Promise<string | null>) {
  return onError(({ networkError, graphQLErrors, operation, forward }) => {
    // if (networkError && "statusCode" in networkError && networkError.statusCode === 400) {
    if (graphQLErrors && graphQLErrors.some((err) => err.extensions && err.extensions.code === "ACCESS_DENIED")) {
      if (!refreshAnonymousTokenPromise) {
        refreshAnonymousTokenPromise = refreshUserAnonymousToken({
          onRefreshed: () => {
            refreshAnonymousTokenPromise = null;
          },
        });
      }
      Sentry.addBreadcrumb({
        category: "error",
        message: `ACCESS_DENIED ${graphQLErrors.find((err) => err.extensions && err.extensions.code === "ACCESS_DENIED")?.message}`,
        level: "log",
      });
      return fromPromise(refreshAnonymousTokenPromise).flatMap((anonymousToken) => {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${anonymousToken}`,
          },
        });

        return forward(operation);
      });
    }
    // }
    //   Sentry.addBreadcrumb({
    //     category: "error",
    //     message: `Network Error ${networkError.statusCode}`,
    //     data: networkError,
    //     level: "log",
    //   });
    // }
    return undefined;
  });
}
