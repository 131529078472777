import React, { ComponentProps, useContext } from "react";
import { Popper } from "@mui/base/Popper";
import { Box, ClickAwayListener, Collapse, Grow, Modal, styled } from "@mui/material";
import SelectMenuContext from "./SelectMenuContext";
import { SelectMenuVariant } from "./constant";

const StyledPopper = styled(Popper)(
  ({ theme }) => `
    z-index: ${theme.zIndex.modal};
    border-radius: 16px;
    margin-top: ${theme.spacing(0.2)} !important;
    background-color: ${theme.palette.grayScale.sixpercent};
    backdrop-filter: blur(46px);
  `,
);

const StyledWrapper = styled(Box)(
  ({ theme }) => `
    justify-content: center;
    align-items: center;
      border-radius: ${theme.borderRadius(1.5)};
    padding: ${theme.spacing(1)};
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
      border-radius: ${theme.borderRadius(1.5)};
    background-color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.border.primary};
    padding-top: ${theme.spacing(2)};
  `,
);

type SelectMenuModalProps = { children: React.ReactNode; clickAwayOpen?: boolean } & ComponentProps<typeof Box>;

export default function SelectMenuModal(props: SelectMenuModalProps) {
  const { width, height, children, clickAwayOpen, ...restProps } = props;
  const { menuState, variant } = useContext(SelectMenuContext);
  const isOpen = Boolean(menuState.anchorEl);

  if (variant === SelectMenuVariant.BOX) {
    return (
      // in is not recognised as a property even though is is a property of collapse
      // @ts-expect-error See above
      <StyledWrapper as={Collapse} in={isOpen} {...restProps}>
        {isOpen && children}
      </StyledWrapper>
    );
  }

  if (variant === SelectMenuVariant.MODAL) {
    return (
      <Modal onClose={menuState.onClose} open={isOpen} sx={{ display: "flex" }}>
        <StyledBox height={height || "100%"} width={width || "100vw"} {...restProps}>
          {children}
        </StyledBox>
      </Modal>
    );
  }

  // default return Popper
  return (
    <StyledPopper anchorEl={menuState.anchorEl} open={isOpen} placement="bottom-start" sx={{ width }} transition>
      {/*
        Todo: create a ClickAwayListener where we don't need to
        create an extra element for it to work.
        (The react-click-away-listener of npm doesn't work)
      */}

      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={clickAwayOpen ? () => { } : menuState.onClose}>
          <Grow {...TransitionProps} style={{ transformOrigin: "0 0 0" }} in={isOpen} {...(isOpen ? { timeout: 250 } : {})}>
            <Box {...restProps}>{children}</Box>
          </Grow>
        </ClickAwayListener>
      )}
    </StyledPopper>
  );
}
